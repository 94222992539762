import { initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'

const firebaseConfigProd = {
  apiKey: 'AIzaSyD9DpTu685iuzdm8R74dJ1nwziPM2ehz0E',
  authDomain: 'clothes-miduhouse.firebaseapp.com',
  projectId: 'clothes-miduhouse',
  storageBucket: 'clothes-miduhouse.firebasestorage.app',
  messagingSenderId: '1082634881602',
  appId: '1:1082634881602:web:27e8f5d819fe322e9f3bdb',
  measurementId: 'G-3ZY437YR0K'
}

const firebaseConfigDev = {
  apiKey: "AIzaSyDU4jPnZoQm3rtv6AgLPZIQ2BHTF5HqgX0",
  authDomain: "clothes-miduhouse-dev.firebaseapp.com",
  projectId: "clothes-miduhouse-dev",
  storageBucket: "clothes-miduhouse-dev.firebasestorage.app",
  messagingSenderId: "40204449620",
  appId: "1:40204449620:web:28dbfcdfc6efd45af5fac1",
  measurementId: "G-JG7KK5TDJ4"
}

const app = initializeApp(process.env.NODE_ENV === 'production' ? firebaseConfigProd : firebaseConfigDev)
export const auth = getAuth(app)
export const googleProvider = new GoogleAuthProvider() 