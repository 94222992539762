import { lazy, Suspense, useEffect, useRef } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import PublicRoute from './components/PublicRoute'
import PrivateRoute from './components/PrivateRoute'
import { environment, loadEnvironment } from './helpers/environment.helper'
import { TokenSelector } from './dux/selectors/auth.selector'
import { logout, renewToken } from './dux/actions/authActions'
import '@fortawesome/fontawesome-free/css/all.min.css'

const Login = lazy(() => import('./components/login/Login'))
const Home = lazy(() => import('./components/home/Home'))
const Upload = lazy(() => import('./components/upload/Upload'))
const AccountManager = lazy(() => import('./components/admin/account-manager/AccountManager'))

const checkFocusDelay = 1000 * 20 //this is must smaller than timeoutDelay
const logoutDelay = 1000 * 30
const renewDelay = 1000 * 60 * 55 // this is must almost equal to firebase token expires

const App = () => {
  const token = useSelector(state => TokenSelector(state))

  const tokenRef = useRef(null)
  const logoutTimerRef = useRef(null)
  const renewIntervalRef = useRef(null)

  const dispatch = useDispatch()

  useEffect(() => {
    loadEnvironment()
      .then(() => {
        ping()
      })
  }, [])

  useEffect(() => {
    tokenRef.current = token
  }, [token])

  const ping = () => {
    checkCurrentTokenAsync()
    
    setInterval(() => {
      if (!tokenRef.current) return

      if ((document.hidden || !document.hasFocus())
        && !logoutTimerRef.current) {
        logoutTimerRef.current = setTimeout(() => {
          dispatch(logout())
          renewIntervalRef.current && clearInterval(renewIntervalRef.current)
          renewIntervalRef.current = null
        }, logoutDelay)
      } else {
        logoutTimerRef.current && clearTimeout(logoutTimerRef.current)
        logoutTimerRef.current = null

        checkCurrentTokenAsync()
          .then(() => {
            if (renewIntervalRef.current) return
            renewIntervalRef.current = setInterval(() => { tryRenewTokenAsync() }, renewDelay)
          })

      }
    }, checkFocusDelay)
  }

  const checkCurrentTokenAsync = async () => {
    const url = new URL('/api/auth/check-current-token', environment.apiUrl)
    try {
      const res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${tokenRef.current}`
        }
      })

      if (res.status === 401) {
        dispatch(logout())
      }
    } catch (error) {
      dispatch(logout())
    }
  }

  const tryRenewTokenAsync = async () => {
    try {
      dispatch(renewToken())
    } catch (error) {
      dispatch(logout())
    }
  }

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path='/login'
            element={<PublicRoute><Login /></PublicRoute>}
          />
          <Route
            path='/'
            element={<PrivateRoute><Home /></PrivateRoute>}
          />
          <Route
            path='/upload'
            element={<PrivateRoute><Upload /></PrivateRoute>}
          />
          <Route
            path='/acc-mng'
            element={<PrivateRoute><AccountManager /></PrivateRoute>}
          />
        </Routes>
      </Suspense>
    </Router>
  )
}

export default App
